
<template>
  <div>
<!-- @change-table="ChangeTable" -->
    <div v-if="isCustomersCom"><customer-orders-com v-model="isCustomersCom" :Id="CustomerTemp.Id" :BranchId="branchFilter"></customer-orders-com>

    </div>
   <div v-if="!isCustomersCom" class="d-flex flex-column ">
    <div class="d-flex align-center pb-5">
            <select-restaurant  class="mb-6" v-model="restaurantFilter" :val="1" />
            <select-bransh class="mb-6" v-model="branchFilter" :RestaurantId="restaurantFilter" />

          </div>
          <!-- :options.sync="options" :sort-by="['Id']"-->
         <v-data-table
          :headers="tableColumns"
          :items="customers"
          item-key="Id"
          :items-per-page="15"
          :search="searchQuery"
          :loading="loading"
          @click:row="v => itemClick(v)"
        >
        <template v-slot:top>
          <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search "
            ></v-text-field>
        </template>
          <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
          <template #[`item.Name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
                <span class="text-xs">{{ item.Description }}</span>
              </div>
            </div>
          </template>
         <!-- <template #[`item.OrdersCount`]="{ item }">
           <p>{{(OrdersCustomers.filter(v=> v.Id == item.Id))[0].count}}</p>
          </template>-->
        </v-data-table></div>

  </div>
</template>

<script>
import SelectBransh from '@/views/Branch/SelectBransh.vue'
import SelectRestaurant from '@/views/Menu/Restaurant/SelectRestaurant.vue'
import { GetForManager } from '@core/api/Customers'
import { onMounted, ref, watch } from '@vue/composition-api'
import CustomerOrdersCom from './CustomerOrdersCom.vue'
// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: { SelectRestaurant, SelectBransh, CustomerOrdersCom },

  setup() {
    const tableColumns = [
    { text: 'Id', value: 'Id', sortable: false, },
    { text: 'Name', value: 'Name', sortable: false, },
    { text: 'PhoneNumber', value: 'PhoneNumber', sortable: false, },
    { text: 'Description', value: 'Description', sortable: false, },
    { text: 'Orders Count', value: 'Status'}
  ]
    const CustomerTemp = ref({ Id:undefined })
    const restaurantFilter = ref (1)
    const Totals = ref (0)
    const OrdersArray = ref ([])
    const OrdersCustomers = ref ([])
    const branchFilter = ref (null)
    const customers = ref([])
    const loading = ref(false)
    const isCustomersCom = ref(false)
    const searchQuery = ref('')
    const fun = () => {
      customers.value.map(x => {
        x.Status = OrdersArray.value.filter(i=> i == id).length
        let id = x.Id
        let b = OrdersArray.value.filter(i=> i == id).length
          let v = {Id:id , count: b}
          OrdersCustomers.value.push(v)
        })
    }
    const getCustomers = () => {
      GetForManager({
        RestaurantId : restaurantFilter.value,
        BranchId : branchFilter.value,
        Any : searchQuery.value,
      })
        .then( res => {
        OrdersArray.value = res.Orders
        Totals.value = res.Totals
        customers.value = JSON.parse(JSON.stringify(res.Items))
        customers.value.map((x, index, arr) => {
          console.log('xxxx', x)
          arr[index].Status = OrdersArray.value.filter(i=> i == arr[index].Id).length
        })
       // fun()
        loading.value = false
        console.log('cust',OrdersCustomers.value)
      })
    }
    const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const itemClick = item =>{
    CustomerTemp.value = item
    isCustomersCom.value = true
    //router.push({ name:'CustomerOrders', params: {id: item.Id, branchid: branchFilter.value}})
  }
  const ChangeTable = () => {
    isCustomersCom.value = false
  }
    onMounted(() => {
      getCustomers()
    })
    watch(
    [
     // options,
      branchFilter,
      //statusFilter,
      restaurantFilter,
      //searchQuery,
    ],
    () => {
      loading.value = true
      console.log('watcher', restaurantFilter.value, branchFilter.value )
      getCustomers()
    },
  )
    return {
      ChangeTable,
      isCustomersCom,
      CustomerTemp,
      itemClick,
      fun,
      OrdersCustomers,
      OrdersArray,
      Totals,
      searchQuery,
      loading,
      restaurantFilter,
      branchFilter,
      options,
      tableColumns,
      getCustomers,
      customers,
    }
  },
}
</script>


