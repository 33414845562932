<template>
  <div>
    <div>
      <v-btn
        @click="Close()"
        fab
        color="warning"
        class="mb-8"
      >
        <v-icon
        x-large
        >
          {{ icons.mdiArrowLeftThick }}
        </v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="tableColumns"
      :items="OrderList"
      item-key="Id"
      :items-per-page="10"
      :expanded.sync="expanded"
      show-expand
      :loading="loading"
    >
      <!-- id -->
      <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
      <!-- Name -->
      <template #[`item.Name`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
            <span class="text-xs">{{ item.Description }}</span>
          </div>
        </div>
      </template>
      <!-- CreationTime -->
      <template #[`item.CreationTime`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">{{
              filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
            }}</span>
          </div>
        </div>
      </template>
      <!-- status -->
      <template #[`item.Status`]="{ item }">
        <v-chip
          small
          :color="
            $store.state.status['orders'].find(obj => {
              return obj.value == item.Status
            }).variant || 'primary'
          "
          :class="`${
            $store.state.status['orders'].find(obj => {
              return obj.value == item.Status
            }).variant || 'primary'
          }--text`"
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ item.Status }}
        </v-chip>
      </template>
      <!-- expandad data -->
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length / 2">
          <v-card outlined>

            <v-row v-for="(orderTransation, i) in item.OrderTransations" :key="i">
              <v-col lg="7" cols="7"
                >{{ orderTransation.Name }}
                <br />
                <span v-for="(Addon, i) in JSON.parse(orderTransation.Addon)" :key="i"
                  >Addon : {{ Addon.Name }} - ({{ Addon.Price }})<br
                /></span>
                <span v-if="orderTransation.Description != ''">Note : {{ orderTransation.Description }} <br /></span>
              </v-col>
              <v-col lg="2" cols="2">
                {{ orderTransation.Qty }}
              </v-col>
              <v-col>
                {{ (parseFloat(orderTransation.TotalPrice) * orderTransation.Qty).toFixed(2) }}
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-1"></v-divider>

            <v-row>
              <v-col lg="6" md="4" sm="4" cols="4">
                <span class="justify-end text-lg-h6 ms-1">Subtotal</span>
              </v-col>
              <v-col lg="6" md="8" sm="8" cols="8">
                <span class="d-flex justify-end mb-6">
                  {{
                    item.OrderTransations.reduce((prev, cur) => {
                      return prev + parseFloat(cur.TotalPrice) * cur.Qty
                    }, 0).toFixed(2)
                  }}
                  JOD
                </span>
              </v-col></v-row
            >
            <v-divider class="mt-1 mb-1"></v-divider>
            <p v-if="item.Description != null" class="mb-1">Description: {{ item.Description }}</p>
             <p v-if="item.TaxAmount > 0" class="mb-1">Tax Amount: {{ item.TaxAmount }} JD</p>
            <p v-if="item.DeliveryPrice > 0" class="mb-1">DeliveryPrice: {{ item.DeliveryPrice.toFixed(3) }} JD</p>
            <p v-if="item.PromoCode != null" class="mb-1">PromoCode: {{ item.PromoCode }}</p>
            <p v-if="item.PromoCode != null" class="mb-1">PromoCodeAmount: {{ item.PromoCodeAmount }}</p>
            <p class="mb-1">TotalAmount: {{ item.TotalAmount }}</p>
          </v-card>
        </td>
        <td :colspan="headers.length / 2">
          <v-card outlined>
            <v-card-title>
              <v-row>
                <v-col lg="12" md="4" sm="4" cols="4">
                  <span class="d-flex justify-start"><h3>Customer & Address</h3></span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="mt-1 mb-1"></v-divider>

            <p class="my-1">Name: {{ item.Name }}</p>
            <p class="mb-1">Payment Type: {{ item.PaymentType }}</p>
            <p class="mb-1">Order Type: {{ item.OrderType }}</p>
            <p v-if="item.ScheduleTimeOrder > '2020-01-01 02:00:00.0000000'" class="mb-1">
              Schedule Time Order: {{ filter.parseTime(item.ScheduleTimeOrder, '{y}-{m}-{d} {h}:{i}') }}
            </p>
            <p class="mb-1">Delivery Type: {{ item.DeliveryType }}</p>
            <p v-if="item.DeliveryCompany != null" class="mb-1">Delivery Company: {{ item.DeliveryCompany }}</p>
            <p class="mb-1">Order Source: {{ item.OrderSource }}</p>
            <p v-if="item.OrderSourceRefNumber != null" class="mb-1">
              Order Source Ref Number: {{ item.OrderSourceRefNumber }}
            </p>

            <p v-if="item.City != null" class="mb-1">City: {{ item.City }}</p>
            <p v-if="item.BranshAddress != null" class="mb-1">Bransh Address: {{ item.BranshAddress }}</p>
            <p v-if="item.AddressDetail != null" class="mb-1">Address Details: {{ item.AddressDetail }}</p>
            <p v-if="item.BuildingNo != null" class="mb-1">Building No: {{ item.BuildingNo }}</p>
            <p v-if="item.StreetNo != null" class="mb-1">Street Name: {{ item.StreetNo }}</p>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <v-snackbar v-model="isFormSubmittedSnackbarVisible" shaped top outlined absolute :light="$vuetify.theme.light" vertical color="error" :timeout="10000">

      <v-card  class=" d-flex justify-start mb-5 mr-5">
          <v-btn
        icon
        @click="isFormSubmittedSnackbarVisible = false"
      >
        <v-icon small>
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
      </v-card>
      <div id="newline">
        {{ snackbarText }}
      </div>
      <v-card  class=" d-flex justify-start mt-10 mr-5">
          <v-btn
        @click="isFormSubmittedSnackbarVisible = false"
        color="info"
        small
      >

         Close
      </v-btn>
      </v-card>
    </v-snackbar>
  </div>
</template>

<script>
import { GetByCustomerIdAndBranchId } from '@core/api/Order'
import { parseTime } from '@core/utils/filter'
import { mdiArrowLeftThick, mdiClose, mdiCommentFlash, mdiReload } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'isCustomersCom',
    event: 'update:is-customers-com',
  },
  props: {
    Id: {
      type: Number,
      required: true,
    },
    BranchId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    //const BranchId = ref(router.currentRoute.params.branchid)
    //const CustomerId = ref(router.currentRoute.params.id)
    const branchId = ref(props.BranchId)
    const CustomerId = ref(props.Id)
    const itemIds = ref([])
    const itemList = ref([])
    const RemovedItems = ref([])
    const snackbarText = ref('<h2>This item(s) is inactive:</h2>')
    const isFormSubmittedSnackbarVisible = ref(false)
    const expanded = ref([])
    const isActive = ref(false)
    const orderTemp = ref({ Id: 0 })
    const OrderList = ref([])
    const options = ref({
      sortBy: ['Id'],
      sortDesc: [false],
    })
    const totalOrderList = ref(0)
    const loading = ref(false)
    const loader = ref(true)
    const tableColumns = [
      { text: 'id', value: 'Id' },
      { text: 'Creation Time', value: 'CreationTime' },
      { text: 'Status', value: 'Status', sortable: false },
    ]
    const fetchOrders = () => {
      GetByCustomerIdAndBranchId({ BranchId: branchId.value, CustomerId: CustomerId.value }).then(res => {
        OrderList.value = res
        totalOrderList.value = res.length
      })

    }
    const Close = () => {
      // emit('change-table')
      console.log('close')
      emit('update:is-customers-com', false)
    }
    onMounted(() => {
      fetchOrders()
    })
    return {
      Close,
      branchId,
      CustomerId,
      snackbarText,
      isFormSubmittedSnackbarVisible,
      RemovedItems,
      itemList,
      itemIds,
      loader,
      fetchOrders,
      isActive,
      orderTemp,
      tableColumns,
      expanded,
      options,
      OrderList,
      totalOrderList,
      loading,
      filter: { parseTime },
      icons: { mdiCommentFlash, mdiReload, mdiClose, mdiArrowLeftThick },
    }
  },
}
</script>

<style scope>
.portrait.v-card {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}


</style>
