import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isCustomersCom)?_c('div',[_c('customer-orders-com',{attrs:{"Id":_vm.CustomerTemp.Id,"BranchId":_vm.branchFilter},model:{value:(_vm.isCustomersCom),callback:function ($$v) {_vm.isCustomersCom=$$v},expression:"isCustomersCom"}})],1):_vm._e(),(!_vm.isCustomersCom)?_c('div',{staticClass:"d-flex flex-column "},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('select-restaurant',{staticClass:"mb-6",attrs:{"val":1},model:{value:(_vm.restaurantFilter),callback:function ($$v) {_vm.restaurantFilter=$$v},expression:"restaurantFilter"}}),_c('select-bransh',{staticClass:"mb-6",attrs:{"RestaurantId":_vm.restaurantFilter},model:{value:(_vm.branchFilter),callback:function ($$v) {_vm.branchFilter=$$v},expression:"branchFilter"}})],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.customers,"item-key":"Id","items-per-page":15,"search":_vm.searchQuery,"loading":_vm.loading},on:{"click:row":function (v) { return _vm.itemClick(v); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VTextField,{attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search "},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})]},proxy:true},{key:"item.Id",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(item.Id)+" ")]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.Name))]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.Description))])])])]}}],null,true)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }